<template>
<div class="mobile-progress-bar-main ">
  <div class="mobile-progress-bar-container ">
    <div class="relative mobile-step-box" >
      <div id="step11" class="div1 process"><span>1.</span></div>
      <div id="step12" class="div2 process"></div>
    </div>
    <div class="relative mobile-step-box">
      <div class="div3"></div>
      <div id="step21" class="div4 "><span>2.</span></div>
      <div id="step22" class="div5"></div>
    </div>
    <div class="relative mobile-step-box">
      <div class="div6"></div>
      <div id="step31" class="div7"><span>3.</span></div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "mobile-progress-bar",
  data: () => ({
  }),
  methods: {
  }
}
</script>

<style scoped>

</style>