<template>
    <div class="stepBar align-middle  mx-auto py-3 flex flex-wrap items-center ">
        <div class="outer-div-1 rounded border relative" id="step1-out" @click="step1">
            <div class="step1  rounded  relative stepProcessColor" id="step1">
                <p tabindex="0" class="focus:outline-none w-full textheader   leading-4 ">Pateikti užsakymą</p>
                <p tabindex="0" class="focus:outline-none w-full textbody leading-4">Informacijos pateikimas</p>
            </div>
        </div>
        <div class="outer-div-2 rounded border relative " id="step2-out" @click="step2">
            <div class="step2 rounded relative" id="step2" >
                <p tabindex="0" class="focus:outline-none textheader w-full   leading-4 ">Užsakymo informacija</p>
                <p tabindex="0" class="focus:outline-none textbody w-full leading-4">Užsakymo suvestinė, komentarai</p>
            </div>
        </div>
        <div class="outer-div-3 rounded border relative " id="step3-out" @click="step3">
            <div class="step3 rounded relative" id="step3" >
                <p tabindex="0" class="focus:outline-none w-full textheader leading-4 ">Patvirtinimas</p>
                <p tabindex="0" class="focus:outline-none textbody w-full leading-4">Užsakymo statusas, mokėjimas</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "progress-bar",
  data: () => ({}),
  methods: {},
}
</script>
<style>

</style>


