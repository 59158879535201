<template>
  <div class="w-full lg:w-7/12 p-5 rounded-lg lg:rounded-l-none scrollbar formCol" style="background-color: #f2f0ec; overflow-y: scroll; -ms-overflow-style: none;"  >
    <div class="px-8 pt-6 pb-8 mb-4 form rounded" >
      <!--   Order Information Header   -->
      <div class="border-b-2 flex justify-between formHeader" @click="isHiddenInfo = !isHiddenInfo; ">
        <div class="">
          <label class="">Informacija</label>
        </div>
        <div class="toggleIcon">
          <span  class="justify-self-end">
            <font-awesome-icon v-if="isHiddenInfo === false" icon="fa-solid fa-angle-down" class="toggle-up"/>
            <font-awesome-icon v-if="isHiddenInfo === true" icon="fa-solid fa-angle-up" class="toggle-down" />
          </span>
        </div>
      </div>
      <!--        Info about order                   -->
      <div class="info-about-order-div" v-if="!isHiddenInfo">
        <div class="info-about-order-header">
          <span>Jūsų užsakymo suvestinė</span>
        </div>
        <div class="">
          <table class="w-full">
            <tr class="border-b border-b-gray-700">
              <td class="border-r border-r-gray-700  ">Produktai</td>
              <td class="border-r border-r-gray-700 whitespace-nowrap ">Kaina</td>
              <td class="border-r border-r-gray-700 whitespace-nowrap ">Kiekis</td>
              <td class="border-l border-l-gray-700 ">Failai</td>
            </tr>
            <tr class="border-b border-b-gray-700" v-for="product in FormData.products" :key="product.id">
              <td class="border-r border-r-gray-700 whitespace-nowrap p-1">{{ product.title }}</td>
              <td class="border-r border-r-gray-700 whitespace-nowrap p-1">{{ product.price+' Eur' }}</td>
              <td class="border-r border-r-gray-700 whitespace-nowrap p-1">{{ product.quantity }}</td>
              <td class="border-l border-l-gray-700 p-1">
                <span v-for="file in product.files" :key="file.id"><font-awesome-icon icon="fa-solid fa-paperclip" class="file-icon" />{{ file.name }}</span>
              </td>
            </tr>
          </table>
        </div>

<!--        <div class="info-about-order-header" >-->
<!--          <span>Papildomos paslaugos</span>-->
<!--        </div>-->
<!--        <div class=" services no-border" >-->
<!--          <div class="inline-flex toggleDiv items-center justify-center flex flex-wrap " v-for="(service, index) in services" :key="service.id">-->
<!--            <label :for="'toggle'+index" class="flex items-center cursor-pointer select-none  h-25" >-->
<!--              <div class=" text-gray-700 font-medium serviceText float-left">-->
<!--                <span class="inline-block">{{ service.name }}</span>-->
<!--                <span class="inline-block">{{ ' ( '+service.price+' EU. )' }}</span>-->
<!--              </div>-->
<!--              <div class="relative">-->
<!--                <input :id="'toggle'+index" v-model="services[index].enabled" type="checkbox" @change="!services[index].enabled, onChangePrice(service)" class="sr-only step3-input" :disabled="disabled">-->
<!--                <div :id="'button'+index" :class="[{ 'GreenInnerButton' : service.enabled, 'GreyInnerButton' : !service.enabled, 'disabledButton' : disabled}]" class="w-14 h-5  rounded-full shadow-inner "  ></div>-->
<!--                <div :id="'dot'+index" :class="[{ 'dot GreenButton' : service.enabled, 'disabledDot' : disabled }]" class="dotBefore absolute w-7 h-7  rounded-full shadow-switch-1 left-0 -top-1 transition"  ></div>-->
<!--              </div>-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->

        <div class="info-about-order-header">
          <span>Kita Informacija</span>
          <div class="info-notes">
            <span>{{  FormDataMain.Order.notes}}</span>
          </div>
        </div>
        <div class="sum-div">
          <span>Suma</span>
          <div class="sum">
            <span>{{ sum.toFixed(2)+' Eur' }}</span>
          </div>
        </div>
      </div>
      <!--   Order Confirmation Header   -->
      <div class="border-b-2 flex justify-between formHeader " @click="isHiddenConfirmation = !isHiddenConfirmation; ">
        <div class="">
          <label class="">Užsakymo patvirtinimas</label>
        </div>
        <div class="toggleIcon">
          <span  class="justify-self-end">
            <font-awesome-icon v-if="isHiddenConfirmation === false" icon="fa-solid fa-angle-down" class="toggle-up"/>
            <font-awesome-icon v-if="isHiddenConfirmation === true" icon="fa-solid fa-angle-up" class="toggle-down" />
          </span>
        </div>
      </div>
      <!--        Order Confirmation                  -->
      <div class="order-confirmation-div" v-if="!isHiddenConfirmation">
        <div>
          <span>
            {{ 'Aš '+FormDataMain.Order.name+confirmationText }}<a href="https://iglo.lt/pirkimo-taisykles-ir-salygos/" class="step3-link border-b-1 border-gray-500">sąlygomis ir taisyklėmis.</a>
          </span>
        </div>
        <div class="mx-auto" v-if="FormDataMain.Order.status == 'confirmation'">
          <button id="input" type="submit" @click="onSubmit"
                  class="text-white order-submit-button mx-auto cursor-pointer step3-input"
          >
            Patvirtinti<div :class="{'show-lds-ring' : lds_ring}" class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </button>
        </div>
      </div>
      <!--   Order status header   -->
      <div class="border-b-2 flex justify-between formHeader " @click="isHiddenStatus = !isHiddenStatus; ">
        <div class="">
          <label class="">Užsakymo statusas</label>
        </div>
        <div class="toggleIcon">
          <span  class="justify-self-end">
            <font-awesome-icon v-if="isHiddenStatus === false" icon="fa-solid fa-angle-down" class="toggle-up"/>
            <font-awesome-icon v-if="isHiddenStatus === true" icon="fa-solid fa-angle-up" class="toggle-down" />
          </span>
        </div>
      </div>
      <!--        Order Status                  -->
      <div class="order-status" v-if="!isHiddenStatus">
        <div id="status" class="step3-status">
          <span class="">{{ status }}</span>
        </div>
        <button v-if="FormDataMain.Order.status == 'payment_required' " @click="getInvoice" class="order-invoice-button" >invoice-{{FormData.order_id}}.pdf</button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios"; // eslint-disable-line

export default {
  name: "main-block-step3",
  props: [
    'FormDataMain',
  ],
  components: {
    axios,// eslint-disable-line
  },
  data: () => ({
    isHiddenInfo: false,
    isHiddenConfirmation: false,
    isHiddenStatus: false,
    sum: 0,
    FormData: {
      order_id: null,
      products: [],
      status: '',
    },
    services: [],
    confirmationText: ' patvirtinu, kad pateikta užsakymo informacija yra teisinga ir nekintanti, susipažinau su ',
    status: '',
    lds_ring: false,
    disabled: false,
    modal: false,
  }),
  mounted() {
    for (const parentProducts of Object.entries(this.FormDataMain.Order.products)){
      this.FormData.products.push(parentProducts[1])
    }
    this.FormData.order_id = this.FormDataMain.Order.order_id;
    this.FormData.status = this.FormDataMain.Order.status;

    if (this.FormData.status === 'confirmation'){
      this.status = 'Tvirtinama';
    }
    if (this.FormData.status === 'confirmed'){
      this.status = 'Patvirtinta';
      this.disabled = true;

      const button = document.getElementById('input');
      button.setAttribute('disabled', 'true');
      if (button.disabled) {
        button.classList.add('disabled-button');
      }

    }
    if (this.FormData.status === 'payment_required'){
      this.status = 'Laukiama mokėjimo';
      document.getElementById("status").classList.add('orangeStatus');
    }
    if (this.FormData.status === 'paid'){
      this.status = 'Apmokėta';
    }
    if (this.FormData.status === 'production'){
      this.status = 'Gaminama';
    }
    if (this.FormData.status === 'shipping'){
      this.status = 'Siunčiama';
    }
    if (this.FormData.status === 'completed'){
      this.status = 'Užsakymas įvykdytas';
      document.getElementById("status").classList.add('greenStatus');
    }

    for (const priceOfProducts of Object.entries(this.FormData.products)){
      this.sum += (priceOfProducts[1].price*priceOfProducts[1].quantity);
    }
    // List of services from BE
    axios
        .get(process.env.VUE_APP_ROOT_API+'/api/v1/public/services')
        .then(response => {
          this.services = response.data;
          for (const services of Object.entries(this.services)) {
            for (const serviceOfForm of Object.entries(this.FormDataMain.Order.products)) {
              if(serviceOfForm[1].service_id && serviceOfForm[1].service_id == services[1].id  ){
                services[1].enabled = true;
                services[1].price = serviceOfForm[1].price;
              }
            }
          }
        })
        .catch(err => {
          console.error(err);
        });

  },
  methods: {
    async onChangePrice(service) {

      if (service.enabled === true){
        const response = await axios.post(
            process.env.VUE_APP_ROOT_API + '/api/v1/public/product/addProduct',
            {
              'order_id': this.FormDataMain.Order.order_id,
              'field_key': 'services',
              'service_id': service.id,
              'enabled': service.enabled,
            }
        );
        this.sum += service.price;
        this.FormData.products.push(response.data);
      }

      if (service.enabled === false){
        await axios.post(
            process.env.VUE_APP_ROOT_API + '/api/v1/public/product/removeProduct',
            {
              'field_key': 'services',
              'service_id': service.id,
              'enabled': service.enabled,
            }
        );
        for( let i = 0; i < this.FormData.products.length; i++){
          if ( this.FormData.products[i].service_id === service.id) {
            this.FormData.products.splice(i, 1);
            this.sum = this.sum - service.price;
          }
        }
      }
    },
    async onSubmit(){

      this.lds_ring = true;

      const button = document.getElementById('input');
      button.setAttribute('disabled', 'true');
      if (button.disabled) {
        button.classList.add('disabled-button');
      }

      this.disabled = true;

      await axios.put(process.env.VUE_APP_ROOT_API + '/api/v1/public/order/finalSubmit',
          {
            'order_id': this.FormDataMain.Order.order_id,
            'price' : this.sum,
          })
          .then(response => {

            if(response){

              document.getElementById("step3").classList.add('stepDoneColor');
              document.getElementById("step3").classList.remove('stepProcessColor');
              document.getElementById("step31").classList.add('success');
              document.getElementById("step31").classList.remove('process');

            }
            this.showModal();

            this.lds_ring = false;
            this.disabled = true;
            this.FormData.status = 'confirmed';
            this.status = 'Patvirtinta';
            this.$cookies.remove('iglo_uid');
          })
          .catch(err => {

            this.showModal(err);
            if ( document.getElementById("step3").classList.contains('stepProcessColor') ){
              document.getElementById("step3").classList.add('stepErrorColor');
              document.getElementById("step3").classList.remove('stepProcessColor');
              document.getElementById("step31").classList.add('alert');
              document.getElementById("step31").classList.remove('process');
            }
            const button = document.getElementById('input');
            button.removeAttribute('disabled');

            const inputs = document.getElementsByClassName('step3-input');
            for(const input of inputs){
              input.removeAttribute('disabled');
            }
            if (!button.disabled) {
              button.classList.remove('disabled-button');
            }

            this.lds_ring = false;
            this.disabled = false;
          });
      console.log(this.FormDataMain.Order.invoiced_at)
    },
    showModal() {
      this.$emit("showModal");
    },
    getInvoice() {
      window.open(process.env.VUE_APP_ROOT_API+'/api/v1/public/order/getInvoice/' + this.FormData.order_id);
    }
  },
  watch() {
    if (this.FormData.status === 'confirmation'){
      this.status = 'Tvirtinama';
    }
    if (this.FormData.status === 'confirmed'){
      this.status = 'Patvirtinta';
    }
    if (this.FormData.status === 'payment_required'){
      this.status = 'Laukiama mokėjimo';
      document.getElementById("status").classList.add('orangeStatus');
    }
    if (this.FormData.status === 'paid'){
      this.status = 'Apmokėta';
    }
    if (this.FormData.status === 'production'){
      this.status = 'Gaminama';
    }
    if (this.FormData.status === 'shipping'){
      this.status = 'Siunčiama';
    }
    if (this.FormData.status === 'completed'){
      this.status = 'Užsakymas įvykdytas';
      document.getElementById("status").classList.add('greenStatus');
    }
  }
}
</script>
