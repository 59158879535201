<template>
  <footer id="footer" role="contentinfo" class="mobile-footer-main">
    <div class="mobile-footer-icons-box">
      <ul class="mobile-footer-ul">
        <li class="mobile-footer-li">
          <a href="https://www.facebook.com/iglo.lt" target="_blank" class="facebook " title="Facebook" aria-label="Facebook" rel="noopener">
            <font-awesome-icon icon="fa-brands fa-facebook" class="footer-icon-fb"/>
          </a>
        </li>
        <li class="mobile-footer-li">
          <a href="https://www.instagram.com/iglo.lt/" target="_blank" class="instagram  " title="Instagram" aria-label="Instagram" rel="noopener">
            <i class="fab fa-instagram"></i>
            <font-awesome-icon icon="fa-brands fa-instagram" class="footer-icon-ig"/>
          </a>
        </li>
      </ul>
    </div>
    <div class="mobile-footer-links-box">
      <div class="">
        <span class="">iglo.lt&nbsp;© 2022&nbsp;&nbsp;</span><br>
        <p><a :href="shopURL+'/pirkimo-taisykles-ir-salygos/'" class="whitespace-pre-wrap">Taisyklės ir sąlygos</a></p>
        <p><a :href="shopURL+'/privatumo-politika/'" class="">Privatumo politika</a></p>
        <p><a :href="shopURL+'/grazinimo-ir-pristatymo-politika/'" class="">Pristatymas ir grąžinimas</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data: () => ({
    shopURL: process.env.VUE_APP_SHOP_API,
  }),
}
</script>

<style scoped>

</style>