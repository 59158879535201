<template>
  <div class="formUpload">
    <v-card class="w-full cursor-pointer">
      <img src="../assets/Path.png" alt="" class="uploadLabelIcon">
      <file-pond
          name="test"
          ref="pond"
          v-bind:allow-multiple="true"
          acceptedFileTypes="image/jpeg, image/png, image/gif"
          minFiles=1
          maxFileSize="1024MB"
          labelMaxFileSizeExceeded="Failo dydis viršija 1GB"
          v-bind:files="files"
          v-on:init="handleFilePondInit"
          @processfile="onProcessFile"
          @addfile="onAddFile"
          class="mx-auto w-full cursor-pointer z-10"
          multiple
      />
      <span class="uploadLabelSpan"> PNG, JPG, GIF up to 1GB</span>
    </v-card>

<!--  server="/api"  -->
  </div>
</template>

<script>

// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond'// eslint-disable-line

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize,
);
setOptions({
  server: {
    url: process.env.VUE_APP_ROOT_API+'/api/v1/public/product/fileUpload',
    headers: {
      'X-CSRF-TOKEN': '{{ csrf_token() }}'
    },
    process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {// eslint-disable-line
      // fieldName is the name of the input field
      // file is the actual file object to send
      const formData = new FormData();
      formData.append('size', file.size);
      formData.append('collection_name', 'product_files');
      formData.append('tmp_model_id', file.lastModified);
      formData.append('file', file, file.name);


      const request = new XMLHttpRequest();
      request.open('POST', process.env.VUE_APP_ROOT_API+'/api/v1/public/product/fileUpload');

      // Should call the progress method to update the progress to 100% before calling load
      // Setting computable to false switches the loading indicator to infinite mode
      request.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      // Should call the load method when done and pass the returned server file id
      // this server file id is then used later on when reverting or restoring a file
      // so your server knows which file to return without exposing that info to the client
      request.onload = function () {
        if (request.status >= 200 && request.status < 300) {
          // the load method accepts either a string (id) or an object
          load(request.responseText);
        } else {
          // Can call the error method if something is wrong, should exit after
          error('oh no');
        }
      };

      request.send(formData);

      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          abort();
        },
      };
    },
  }
});


export default {
  data: function () {
    return {
      files: [],
      customAPI: process.env.VUE_APP_ROOT_API+'/api/v1/public/product/fileUpload',
      product_id: null,
    };
  },
  methods: {
    handleFilePondInit: function () {
      // console.log('filepond initialized');
      // FilePond instance methods are available on `this.$refs.pond`
    },
    onProcessFile(error, file) {
      console.log('file processed', { error, file })
    },
    onAddFile(error, file) {
      console.log('file has been added', {error, file});
    },
    productId(id) {
      this.product_id = id;
    }
  },
  components: {
    FilePond,
  },

};
</script>
<style lang="css">
/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
  cursor: pointer;
}

/* the text color of the drop label*/
.filepond--drop-label {
  color: #555;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  top: 50px;
  font-family: Tahoma;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.filepond--root{
  height: 140px;

}
.filepond--credits {
  display: none;
}

/* underline color for "Browse" button */
.filepond--label-action {
  text-decoration-color: #aaa;
}

/* the background color of the filepond drop area */
.filepond--panel-root {
  background-color: #eee;
}

/* the border radius of the drop area */
.filepond--panel-root {
  border-radius: 0.5em;
}

/* the border radius of the file item */
.filepond--item-panel {
  border-radius: 0.5em;
}

/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
  background-color: #555;
}

/* the background color of the drop circle */
.filepond--drip-blob {
  background-color: #999;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
  color: white;
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}

/* the text color of the file status and info labels */
.filepond--file {
  color: white;
}

/* error state color */
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
  background-color: red;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: green;
}

/* bordered drop area */
.filepond--panel-root {
  background-color: transparent;
  border: 2px dashed #999;
}
</style>
