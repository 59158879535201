<template >
    <div class="box-content mx-auto">
        <div class="h-9/12 mx-auto" >
            <div id="containerBox" class="flex justify-center mx-auto">
                <!-- Row -->
                <div class="w-full xl:full lg:w-11/12 flex border rounded-l-lg formRow" >
                    <!-- Col -->
                    <div class="w-6/12 lg:block lg:w-6/12 bg-auto rounded-lg relative imgCol">
                        <img src="../assets/table_logo.png" alt="" class="formImage object-cover">
                        <div class="imageTextDiv">
                            <h3 class="imageHeader">Užsakymo forma</h3>
                            <span class="imageText">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias animi aspernatur, consectetur debitis dolorem ea eius nisi officiis perspiciatis quae quas qui similique tenetur!</span>
                        </div>
                    </div>
                    <!-- Col -->
                    <div v-if="FormData.Order.status === 'submited' || FormData.Order.status === 'filling' || FormData.Order.status === 'info_required'" class="w-full lg:w-7/12 p-5 rounded-lg lg:rounded-l-none scrollbar formCol" style="background-color: #f2f0ec; overflow-y: scroll; -ms-overflow-style: none;"  >
                        <form enctype="multipart/form-data" @submit.prevent="onSubmit" class="pt-6 pb-8 mb-4 form rounded" >
                            <!--       Form Header for client          -->
                            <div class="flex justify-between formHeader" @click="isHiddenClient = !isHiddenClient;">
                                <div class="">
                                    <label>Kliento informacija</label>
                                </div>
                                <div class="orangeValidation header-validation-mark--client" v-show="FormData.Order.comments !== null"><p>!</p></div>
                                <div class="toggleIcon">
                                  <span  class="justify-self-end">
                                    <font-awesome-icon v-if="isHiddenClient === false" icon="fa-solid fa-angle-down" class="toggle-up"/>
                                    <font-awesome-icon v-if="isHiddenClient === true" icon="fa-solid fa-angle-up" class="toggle-down" />
                                  </span>
                                </div>
                            </div>

                            <!--        Client's Info                    -->
                            <div class="inputsDiv" v-show="!isHiddenClient">
                                <div v-show="FormData.Order.comments !== null" class="admin-message message--client">
                                  <p>Vadybininko žinutė:</p><br>
                                  <p v-html="FormData.Order.comments"></p>
                                </div>
                                <div v-show="!isHiddenClient"  class="everyInputDiv">
                                  <input v-model="FormData.Order.email"
                                         @change="changes('email')"
                                         type="email"
                                         name="email"
                                         id="email"
                                         class="input w-full formClientInput mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                         placeholder="El. paštas *" />
                                  <span class="error-message" v-if="errors.email.length > 0">{{ String(errors.email) }}</span>
                                </div>
                                <div class="visible-client-inputs">
                                  <div class="everyInputDiv">
                                    <input v-model="FormData.Order.name"
                                           @change="changes('name')"
                                           class="input w-full text-sm leading-tight formClientInput text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                           id="name"
                                           name="name"
                                           type="text"
                                           placeholder="Vardas, Pavardė / Įmonės pavadinimas *" />
                                    <span class="error-message" v-if="errors.name.length > 0">{{ String(errors.name) }}</span>
                                  </div>
                                  <div v-show="!isHiddenClient"  class="everyInputDiv">
                                    <input v-model="FormData.Order.phone"
                                           @change="changes('phone')"
                                           type="tel"
                                           name="phone"
                                           id="phone"
                                           class="input w-full formClientInput mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                           placeholder="Telefono numeris" />
                                    <span class="error-message" v-if="errors.phone.length > 0">{{ String(errors.phone) }}</span>
                                  </div>
                                  <div class="flex items-start flex-wrap everyInputDiv" v-show="!isHiddenClient">
                                    <div class="flex items-center ">
                                      <input v-model="FormData.Order.acceptance"
                                             @change="changes('acceptance')"
                                             type="checkbox"
                                             name="terms"
                                             id="terms"
                                             class="input formCheckbox focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"/>
                                      <br>
                                      <label for="terms" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400">Privatumo politika ir paslaugų teikimo sutikimas *.</label>
                                    </div>
                                    <div>
                                      <span class="error-message everyInputDiv" v-show="errors.acceptance.length > 0">{{ String(errors.acceptance) }}</span>
                                    </div>
                                  </div>
                                  <div class="flex items-start everyInputDiv" v-show="!isHiddenClient">
                                    <div class="flex items-center h-5">
                                      <input v-model="FormData.Order.company"
                                             @change="companyChanges('company')"
                                             type="checkbox"
                                             name="company"
                                             id="company"
                                             class="input formCheckbox focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"/>
                                    </div>
                                    <label for="company" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400">Pažymėti jei klientas yra juridinis asmuo.</label>
                                  </div>
                                  <div v-show="FormData.Order.client_id !== null" id="company" class="client-company-info-wrapper" :class="{'visible-company' : FormData.Order.company}">
                                    <div :class="{'visible-company' : FormData.Order.company}" class="client-company-info-input">
                                      <input id="company_info" v-model="FormData.Order.company_code" @change="companyChanges('company_code')" class="input w-full text-sm leading-tight formClientInput text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"  name="company_code" type="text" placeholder="Įimonės kodas." />
                                      <span class="error-message" v-if="errors.company_code.length > 0">{{ String(errors.company_code) }}</span>
                                    </div>
                                    <div id="company_info" :class="{'visible-company-info' : FormData.Order.company}" class="client-company-info-input">
                                      <input v-model="FormData.Order.company_vat" @change="companyChanges('company_vat')" class="input w-full text-sm leading-tight formClientInput text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"  name="company_vat" type="text" placeholder="Įmonės PVM kodas." />
                                      <span class="error-message" v-if="errors.company_vat.length > 0">{{ String(errors.company_vat) }}</span>
                                    </div>
                                    <div id="company_info" :class="{'visible-company-info' : FormData.Order.company}" class="client-company-info-input">
                                      <input v-model="FormData.Order.company_address" @change="companyChanges('company_address')" class=" input w-full text-sm leading-tight formClientInput text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"  name="company_address" type="text" placeholder="Įmonės adresas." />
                                      <span class="error-message" v-if="errors.company_address.length > 0">{{ String(errors.company_address) }}</span>
                                    </div>
                                  </div>
                                </div>

                            </div>

                            <!--          Form Header for product                  -->
                            <div class="border-b-2 flex justify-between border-gray-300 formHeader" @click="isHiddenProduct = !isHiddenProduct; ">
                                <div class="relative">
                                    <label>Produkto informacija</label>
                                </div>
                                <div class="toggleIcon">
                                  <span  class="justify-self-end">
                                    <font-awesome-icon v-if="isHiddenProduct === false" icon="fa-solid fa-angle-down" class="toggle-up"/>
                                    <font-awesome-icon v-if="isHiddenProduct === true" icon="fa-solid fa-angle-up" class="toggle-down" />
                                  </span>
                                </div>
                            </div>

                            <!--         Product's Info                   -->
                            <div v-show="!isHiddenProduct" class="product--main">
                              <div class="product-wrapper">
                                <div v-for="(product, index) in FormData.Order.products" :key="index">
                                  <div v-if="FormData.Order.products[index].type !== 'service'" class="product-container">
                                    <div class="product-header">
                                      <h6>{{ FormData.Order.products[index].title }}</h6>
                                      <span>
                                        <font-awesome-icon icon="fa-solid fa-xmark"  class="delete-product-button cursor-pointer" title="Pašalinti produktą" @click="deleteProduct(product, index)" />
                                        <font-awesome-icon v-if="!hiddenProducts.includes(product.id)" icon="fa-solid fa-angle-down" class="toggle-up" @click="hideShowProduct(product.id)"/>
                                        <font-awesome-icon v-if="hiddenProducts.includes(product.id)" icon="fa-solid fa-angle-up" class="toggle-down" @click="hideShowProduct(product.id)"/>
                                        <div class="orangeValidation header-validation-mark--product" v-show="product.admin_comments"><p>!</p></div>
                                      </span>
                                    </div>
                                    <div  class="product-inputs" :class="{'show-single-product' : !hiddenProducts.includes(product.id)}">
                                      <div v-show="product.admin_comments" class="admin-message message--product">
                                        <p>Vadybininko žinutė:</p><br>
                                        <p v-html="product.admin_comments"></p>
                                      </div>
                                      <div  class="product-inputs-wrapper">
                                        <input v-model="FormData.Order.products[index].title"
                                               @change="productChanges('title', product, index)"
                                               class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline input"
                                               :id="'title'+index" name="title" type="text" placeholder="Pavadinimas*"/>
                                        <div v-for="(error, indexs) in errors.product" :key="error[indexs]">
                                          <span class="error-message"
                                                v-if="error[1].length > 0 && error[0] === FormData.Order.products[index].id">
                                            {{ String(error[1]) }}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="product-inputs-wrapper">
                                        <textarea v-model="FormData.Order.products[index].description"
                                                @change="productChanges('description', product, index)" name="description"
                                                :id="'description'+index" rows="4"
                                                class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline input"
                                                placeholder="Produkto informacija, aprašymas, išmatavimai ir kt. Galite įkelti IG ar pinterest nuorodas."></textarea>
                                        <input type="text" class="product-input-quantity input" v-model="FormData.Order.products[index].quantity" @change="productChanges('quantity', product, index)" placeholder="Kiekis">
                                      </div>
                                      <div class="product-inputs-wrapper">
                                        <FileUpload ref="fileUpload" @change="productChanges" class="input" ></FileUpload>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                                <div class="product-container-new" v-if="FormData.Order.products.length === 0">
                                  <div class="product-inputs-wrapper">
                                    <input v-model="tmpProduct.title" @change="productChanges('title')"
                                           class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline input"
                                           id="title" name="title" type="text" placeholder="Pavadinimas*"
                                    />
                                  </div>
                                  <div class="product-inputs-wrapper ">
                                    <textarea v-model="tmpProduct.description" @change="productChanges('description')"
                                              name="description" rows="4"
                                              class=" input w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                              placeholder="Produkto informacija, aprašymas, išmatavimai ir kt. Galite įkelti IG ar pinterest nuorodas.">
                                     </textarea>
                                    <input type="text" class="product-input-quantity input" v-model="tmpProduct.quantity" @change="productChanges('quantity')" placeholder="Kiekis">
                                  </div>
                                  <div class="product-inputs-wrapper">
                                    <FileUpload ref="fileUpload" @change="productChanges" class="input"></FileUpload>
                                  </div>
                                </div>
                              </div>
                              <div class="flex">
                                <button class="add-product-button mx-auto input" @click="addProduct()">Pridėti kitą produktą</button>
                              </div>
                              <span class="error-message mx-auto" v-if="errors.other.length > 0">{{ String(errors.other) }}</span>
                            </div>

                            <!--          Form Header for services                -->
                            <div class="flex justify-between formHeader" @click="isHiddenService = !isHiddenService; ">
                                <div class="relative">
                                    <label for="additionalServ">Papildomos paslaugos</label>
                                    <font-awesome-icon icon="fa-regular fa-circle-question" class="text-gray-500 ml-3 cursor-pointer explanationIcon" v-show="windowWidth >= 800" v-on:mouseover="serviceExplanationUp" v-on:mouseleave="serviceExplanationDown"/>
                                    <div v-show="showServiceExplanation" class="serviceExplanation">
                                      <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque consequuntur dolorem doloremque ducimus </span>
                                    </div>
                                </div>
                                <div class="toggleIcon">
                                  <span class="justify-self-end">
                                    <font-awesome-icon v-if="isHiddenService === false" icon="fa-solid fa-angle-down" class="toggle-up"/>
                                    <font-awesome-icon v-if="isHiddenService === true" icon="fa-solid fa-angle-up" class="toggle-down" />
                                  </span>
                                </div>
                            </div>

                            <!--         Services                 -->
                            <div class="content-center services" v-show="!isHiddenService">
                                <div class="inline-flex toggleDiv items-center justify-center  flex flex-wrap " v-for="(service, index) in FormData.services_list" :key="service.id">
                                    <label :for="'toggle'+index" class="flex items-center cursor-pointer select-none " >
                                        <div class="text-gray-700 font-medium serviceText float-left">
                                          <span class="inline-block">{{ service.name }}</span>
                                          <span class="inline-block">{{ ' ( '+service.price+' EU. )' }}</span>
                                        </div>
                                        <div class="relative toggleBox" >
                                            <input :id="'toggle'+index" v-model="FormData.services_list[index].enabled" type="checkbox" @change="!FormData.services_list[index].enabled, serviceChanges('services', FormData.services_list[index])" class="sr-only input">
                                            <div :class="[{ 'GreenInnerButton' : service.enabled, 'GreyInnerButton' : !service.enabled }]" class="w-14 h-5  rounded-full shadow-inner"></div>
                                            <div :class="[{ 'dot GreenButton' : service.enabled }]" class="dotBefore absolute w-7 h-7  rounded-full shadow-switch-1 left-0 -top-1 transition" ></div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <!--          Form Header for additional info/notes                -->
                            <div class="flex justify-between formHeader formHeader-last" @click="isHiddenNotes = !isHiddenNotes; ">
                                <div class="relative">
                                    <span class="">Pastabos užsakymui</span>
                                    <font-awesome-icon icon="fa-regular fa-circle-question" class="text-gray-500 ml-3 cursor-pointer explanationIcon" v-show="windowWidth >= 800" v-on:mouseover="notesExplanationUp" v-on:mouseleave="notesExplanationDown"/>
                                    <div v-show="showNotesExplanation" class="noteExplanation">
                                      <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque consequuntur dolorem doloremque ducimus </span>
                                    </div>
                                </div>
                                <div class="orangeValidation header-validation-mark" v-show="FormData.Order.admin_comments !== null"><p>!</p></div>
                                <div class="toggleIcon">
                                  <span class="justify-self-end ">
                                    <font-awesome-icon v-if="isHiddenNotes === false" icon="fa-solid fa-angle-down" class="toggle-up"/>
                                    <font-awesome-icon v-if="isHiddenNotes === true" icon="fa-solid fa-angle-up" class="toggle-down" />
                                  </span>
                                </div>
                            </div>

                            <!--         Additional Info/Notes                 -->
                            <div class="inputsDivLast" v-show="!isHiddenNotes">
                                <div v-show="FormData.Order.admin_comments !== null" class="admin-message message--order">
                                  <p>Vadybininko žinutė:</p><br>
                                  <p v-html="FormData.Order.admin_comments"></p>
                                </div>
                                <div class="sm:col-span-2">
                                    <textarea v-model="FormData.Order.notes"  v-on:change="changes('notes')"
                                              rows="6"
                                              class=" input block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                              placeholder="Pastabos.">
                                    </textarea>
                                </div>
                            </div>

                            <!--         Submit button                 -->
                            <div class="form-submit-button-wrapper mx-auto">
                                <button id="submitButton"
                                        type="submit"
                                        @click="handleSubmit"
                                        class="text-white form-submit-button mx-auto">Pateikti <span :class="{'show-lds-ring' : lds_ring}" class="lds-ring"><span></span><span></span><span></span><span></span></span></button>
                                <p v-show="errors.acceptance.length > 0" class="error-message text-center">Formoje yra klaidų, prašome jas pašalinti prieš pateikiant.</p>
                                <p @click="clearCookies" class="text-gray-500 cursor-pointer">Pildyti naują formą.</p>
                            </div>
                        </Form>
                    </div>
                    <mainBlockStep3  @showModal="showModalFromChild"
                                     ref="mainBlockStep3"
                                     :FormDataMain="FormData"
                                     v-if="FormData.Order.status === 'confirmation' || FormData.Order.status === 'confirmed' ||
                                     FormData.Order.status === 'payment_required' || FormData.Order.status === 'paid'||
                                     FormData.Order.status === 'production' || FormData.Order.status === 'shipping' ||
                                     FormData.Order.status === 'completed'"
                    />
                </div>
            </div>
        </div>
    </div>
    <Modal v-if="Modal === true"
           v-bind:email="FormData.Order.email"
           v-bind:errors="errors"
           @close="this.Modal = !this.Modal" >
    </Modal>
    <ProgressBar ref="progressBar" v-show="windowWidth > 800" />
    <mobileProgressBar v-show="windowWidth <= 800" />
</template>

<script>
// import { reactive } from 'vue'
import axios from 'axios'
import ProgressBar from './progress-bar.vue'
import Modal from './modal-element'
import FileUpload from './file-upload-element'
import mobileProgressBar from "@/components/mobile-progress-bar";
import mainBlockStep3 from "@/components/main-block-step3";

export default {
    components: {
        ProgressBar,
        Modal,
        FileUpload,
        mobileProgressBar,
        mainBlockStep3,
        axios,// eslint-disable-line
    },
    setup: () => ({

    }),
    data: () => ({
      FormData: {
        Order: {
          order_id: null,
          acceptance: false,
          client_id: null,
          name: '',
          email: '',
          phone: '',
          company: false,
          company_code: '',
          company_vat: '',
          company_address: '',
          products: [],
          status: 'filling',
          notes: '',
          admin_comments: null,
          comments: null,
          invoiced_at: null,
          fieldKey: '',
        },
        services_list: [],
      },
      tmpProduct: {
        title: '',
        description: '',
        files: [],
        quantity: null,
      },
      mobileNotesValidated: false,
      mobileProductValidated: false,
      Modal: false,
      isHiddenProduct: false,
      isHiddenClient: false,
      isHiddenService: false,
      isHiddenNotes: false,
      errors: {
        email: [],
        name: [],
        phone: [],
        acceptance: [],
        company: [],
        company_vat: [],
        company_code: [],
        company_address: [],
        product: [],
        other: [],
        global: []
      },
      showServiceExplanation: false,
      showNotesExplanation: false,
      windowWidth: window.innerWidth,
      hiddenProducts: [],
      lds_ring: false,
    }),
    async mounted() {
      await this.getOrder();

      // Cheking order status to show it on progress bar
      if(this.FormData.Order.status === 'filling now'){
        const items = document.getElementsByClassName('step1');

        for(const item of items){
          item.classList.add('stepProccessColor');
        }

        const mobilePB1 = document.getElementsByClassName('div1');

        for(const item of mobilePB1){
          item.classList.add('process');
        }
        const mobilePB2 = document.getElementsByClassName('div2');

        for(const item of mobilePB2){
          item.classList.add('process');
        }
      }
      if(this.FormData.Order.status === 'info_required'){
        if ( document.getElementById("step1").classList.contains('stepProcessColor') ){
          const step1 = document.getElementsByClassName('step1');

          for(const item of step1){
            item.classList.add('stepDoneColor');
            item.classList.remove('stepProcessColor')
          }
          const step1out = document.getElementsByClassName('outer-div-1');

          for(const item of step1out){
            item.classList.add('stepDoneColor');
          }
          const mobilePB1 = document.getElementsByClassName('div1');

          for(const item of mobilePB1){
            item.classList.add('success');
            item.classList.remove('process')
          }
          const mobilePB2 = document.getElementsByClassName('div2');

          for(const item of mobilePB2){
            item.classList.add('success');
            item.classList.remove('process')
          }
        }
        const step2 = document.getElementsByClassName('step2');

        for(const item of step2){
          item.classList.add('stepProcessColor');
        }

        const mobilePB4 = document.getElementsByClassName('div4');

        for(const item of mobilePB4){
          item.classList.add('processr');
        }
        const mobilePB5 = document.getElementsByClassName('div5');

        for(const item of mobilePB5){
          item.classList.add('process');
        }
      }
      if(this.FormData.Order.status === 'confirmation'){
        if ( document.getElementById("step1").classList.contains('stepProcessColor') ){
          {
            const step1 = document.getElementsByClassName('step1');

            for(const item of step1){
              item.classList.add('stepDoneColor');
              item.classList.remove('stepProcessColor')
            }
            const step1out = document.getElementsByClassName('outer-div-1');

            for(const item of step1out){
              item.classList.add('stepDoneColor');
            }
            const mobilePB1 = document.getElementsByClassName('div1');

            for(const item of mobilePB1){
              item.classList.add('success');
              item.classList.remove('process')
            }
            const mobilePB2 = document.getElementsByClassName('div2');

            for(const item of mobilePB2){
              item.classList.add('success');
              item.classList.remove('process')
            }
          }
        }
        const step2 = document.getElementsByClassName('step2');

        for(const item of step2){
          item.classList.add('stepDoneColor');
        }
        const step2out = document.getElementsByClassName('outer-div-2');

        for(const item of step2out){
          item.classList.add('stepDoneColor');
        }

        const mobilePB4 = document.getElementsByClassName('div4');

        for(const item of mobilePB4){
          item.classList.add('success');
        }
        const mobilePB5 = document.getElementsByClassName('div5');

        for(const item of mobilePB5){
          item.classList.add('success');
        }

        const step3 = document.getElementsByClassName('step3');

        for(const item of step3){
          item.classList.add('success');
        }

        const mobilePB7 = document.getElementsByClassName('div7');

        for(const item of mobilePB7){
          item.classList.add('process');
        }

      }
      if(this.FormData.Order.status === 'confirmed'){
        if ( document.getElementById("step1").classList.contains('stepProcessColor') ){
          {
            const step1 = document.getElementsByClassName('step1');

            for(const item of step1){
              item.classList.add('stepDoneColor');
              item.classList.remove('stepProcessColor')
            }
            const step1out = document.getElementsByClassName('outer-div-1');

            for(const item of step1out){
              item.classList.add('stepDoneColor');
            }

            const mobilePB1 = document.getElementsByClassName('div1');

            for(const item of mobilePB1){
              item.classList.add('success');
              item.classList.remove('process')
            }
            const mobilePB2 = document.getElementsByClassName('div2');

            for(const item of mobilePB2){
              item.classList.add('success');
              item.classList.remove('process')
            }
          }
        }
        const step2 = document.getElementsByClassName('step2');

        for(const item of step2){
          item.classList.add('stepDoneColor');
        }
        const step2out = document.getElementsByClassName('outer-div-2');

        for(const item of step2out){
          item.classList.add('stepDoneColor');
        }

        const mobilePB4 = document.getElementsByClassName('div4');

        for(const item of mobilePB4){
          item.classList.add('success');
          item.classList.remove('process')
        }
        const mobilePB5 = document.getElementsByClassName('div5');

        for(const item of mobilePB5){
          item.classList.add('success');
          item.classList.remove('process')
        }

        const step3 = document.getElementsByClassName('step3');
        for(const item of step3){
          item.classList.add('stepDoneColor');
        }

        const mobilePB7 = document.getElementsByClassName('div7');

        for(const item of mobilePB7){
          item.classList.add('success');
          item.classList.remove('process')
        }
      }

      // List of services from BE
      axios
      .get(process.env.VUE_APP_ROOT_API+'/api/v1/public/services')
      .then(response => {
        this.FormData.services_list = response.data;
        for (const services of Object.entries(this.FormData.services_list)) {
          for (const serviceOfForm of Object.entries(this.FormData.Order.products)) {
            if(serviceOfForm[1].service_id && serviceOfForm[1].service_id == services[1].id  ){
              services[1].enabled = true;
              services[1].price = serviceOfForm[1].price;
            }
          }
        }
      })
      .catch(err => {
        console.error(err);
      });

    },
    methods: {
        async getOrder() {
          let x = new URL(location.href)
          let token = false;
          let token_from_link = x.searchParams.get('token');

          if ( token_from_link ) {
            token = token_from_link;

            x.searchParams.delete('token');
            window.history.replaceState('', '', x.href);

            this.$cookies.set('iglo_token', token, 60 * 60 * 12);
          }
          else if( this.$cookies.isKey('iglo_token') === true  ) {
            token = this.$cookies.get('iglo_token');
          }
          if ( token ) {

            const response = await axios.post(
                process.env.VUE_APP_ROOT_API + '/api/v1/public/order/getOrder', {
                  'token' : token
                }
            );

            if( !response ) return;

            console.log(response)
            this.FormData.Order = response.data[0];
            this.$cookies.set('iglo_token', response.data[1] , 60 * 60 * 12)
          }
        },
        async handleSubmit() {
          if(this.FormData.Order.company === false) {
            this.FormData.Order.company_code = '';
            this.FormData.Order.company_vat = '';
            this.FormData.Order.company_address = '';
          }

          const button = document.getElementById('submitButton');
          button.setAttribute('disabled', 'true');
          if (button.disabled) {
            button.classList.add('disabled-button');
          }

          const inputs = document.getElementsByClassName('input');
          for(const input of inputs){
            input.setAttribute('disabled', 'true');
          }

          this.lds_ring = true;

          if(this.FormData.Order.acceptance === true) {

            this.errors.acceptance = [];

            await axios.put(process.env.VUE_APP_ROOT_API + '/api/v1/public/order/onSubmit',
                {
                  'order_id': this.FormData.Order.order_id,
                  'notes' : this.FormData.Order.notes,
                  'acceptance' : this.FormData.Order.acceptance,
                  'status' : this.FormData.Order.status
                })
                .then(response => {
                  console.log('success submit')

                  if(response){
                    if (this.FormData.Order.status === 'filling') {
                      if ( document.getElementById("step1").classList.contains('stepProcessColor') ){
                        {
                          document.getElementById("step1").classList.add('stepDoneColor');
                          document.getElementById("step1").classList.remove('stepProcessColor');
                          document.getElementById("step11").classList.add('success');
                          document.getElementById("step11").classList.remove('alert');
                          document.getElementById("step12").classList.add('success');
                          document.getElementById("step12").classList.remove('alert');
                        }
                      }
                      if(document.getElementById("step1").classList.contains('stepErrorColor')){
                        document.getElementById("step1").classList.add('stepDoneColor');
                        document.getElementById("step1").classList.remove('stepErrorColor');
                        document.getElementById("step11").classList.add('success');
                        document.getElementById("step11").classList.remove('alert');
                        document.getElementById("step12").classList.add('success');
                        document.getElementById("step12").classList.remove('alert');
                        // this.errors = [];
                      }
                    }
                    if (this.FormData.Order.status === 'info_required') {
                      if ( document.getElementById("step2").classList.contains('stepProcessColor') ){
                        {
                          document.getElementById("step2").classList.add('stepDoneColor');
                          document.getElementById("step2").classList.remove('stepProcessColor');
                          document.getElementById("step21").classList.add('success');
                          document.getElementById("step21").classList.remove('alert');
                          document.getElementById("step22").classList.add('success');
                          document.getElementById("step22").classList.remove('alert');
                        }
                      }
                      if(document.getElementById("step2").classList.contains('stepErrorColor')){
                        document.getElementById("step2").classList.add('stepDoneColor');
                        document.getElementById("step2").classList.remove('stepErrorColor');
                        document.getElementById("step21").classList.add('success');
                        document.getElementById("step21").classList.remove('alert');
                        document.getElementById("step22").classList.add('success');
                        document.getElementById("step22").classList.remove('alert');
                        // this.errors = [];
                      }
                    }

                  }

                  this.showModal();

                  this.lds_ring = false;

                  this.$cookies.remove('iglo_token')
                })
                .catch(err => {
                  //save errors and show in modal
                  this.errors.global.push(err.response.data.message);
                  console.error(this.errors)
                  this.showModal();

                  if (this.FormData.Order.status === 'filling') {
                    if ( document.getElementById("step1").classList.contains('stepProcessColor') ){
                      document.getElementById("step1").classList.add('stepErrorColor');
                      document.getElementById("step1").classList.remove('stepProcessColor');
                      document.getElementById("step11").classList.add('alert');
                      document.getElementById("step11").classList.remove('success');
                      document.getElementById("step12").classList.add('alert');
                      document.getElementById("step12").classList.remove('success');
                    }
                  }
                  if (this.FormData.Order.status === 'info_required') {
                    if ( document.getElementById("step2").classList.contains('stepProcessColor') ){
                      document.getElementById("step2").classList.add('stepErrorColor');
                      document.getElementById("step2").classList.remove('stepProcessColor');
                      document.getElementById("step21").classList.add('alert');
                      document.getElementById("step21").classList.remove('success');
                      document.getElementById("step22").classList.add('alert');
                      document.getElementById("step22").classList.remove('success');
                    }
                  }

                  const button = document.getElementById('submitButton');
                  button.removeAttribute('disabled');
                  button.classList.remove('disabled-button');

                  const inputs = document.getElementsByClassName('input');
                  for(const input of inputs){
                    input.removeAttribute('disabled');
                  }

                  this.lds_ring = false;
                });
          }
          else {
            const button = document.getElementById('submitButton');
            button.removeAttribute('disabled');
            button.classList.remove('disabled-button');


            const inputs = document.getElementsByClassName('input');
            for(const input of inputs){
              input.removeAttribute('disabled');
            }

            this.lds_ring = false;

            this.errors.acceptance = [];
            this.errors.acceptance.push('Norint tęsti užsakymą, prašome pažymėti šį laukelį.')
          }
        },
        async changes(field_key) {
          if (this.FormData.Order.order_id === null) {

            if (field_key === 'email' && this.validateEmail() === true) {

              const response = await axios.post(process.env.VUE_APP_ROOT_API + "/api/v1/public/order/store", this.FormData.Order);
              this.FormData.Order.order_id = response.data[0].id;
              this.FormData.Order.client_id = response.data[0].client_id;

              if(this.$cookies.isKey('iglo_token') === false) {
                this.$cookies.set("iglo_token", response.data[1], 60 * 60 * 12);
              }

              const client = await axios.get(process.env.VUE_APP_ROOT_API + "/api/v1/public/client/getClient?client_id="+this.FormData.Order.client_id )
              this.FormData.Order.name = client.data.name;
              this.FormData.Order.phone = client.data.phone;
              this.FormData.Order.company = client.data.company;
              this.FormData.Order.company_code = client.data.company_code;
              this.FormData.Order.company_vat = client.data.company_vat;
              this.FormData.Order.company_address = client.data.company_address;

            }
          } else {

            if (field_key === 'acceptance'){
              if (this.FormData.Order.acceptance === true) {
                this.errors.acceptance = [];
                await axios.put(
                    process.env.VUE_APP_ROOT_API + '/api/v1/public/order/update',
                    {
                      'order_id': this.FormData.Order.order_id,
                      'field_key': field_key,
                      'field_value': this.FormData.Order[field_key]
                    }
                );
              } else {

                this.errors.acceptance = [];
                this.errors.acceptance.push('Norint tęsti užsakymą, prašome pažymėti šį laukelį.')
              }
            }
            if (field_key === 'notes'){
              await axios.put(
                  process.env.VUE_APP_ROOT_API + '/api/v1/public/order/update',
                  {
                    'order_id': this.FormData.Order.order_id,
                    'field_key': field_key,
                    'field_value': this.FormData.Order[field_key]
                  }
              );
            }
            if (field_key === 'email' && this.validateEmail() === true) {

              if (this.FormData.Order.email.trim().length > 0) {
                await axios.put(
                    process.env.VUE_APP_ROOT_API + '/api/v1/public/client/update',
                    {
                      'client_id': this.FormData.Order.client_id,
                      'field_key': field_key,
                      'field_value': this.FormData.Order[field_key]
                    }
                );
              }
            }
            if (field_key === 'name') {

              if (this.FormData.Order.name.trim().length > 2) {
                this.errors.name = [];
                await axios.put(
                    process.env.VUE_APP_ROOT_API + '/api/v1/public/client/update',
                    {
                      'client_id': this.FormData.Order.client_id,
                      'field_key': field_key,
                      'field_value': this.FormData.Order[field_key]
                    }
                );
              } else {
                this.errors.name = [];
                this.errors.name.push('Vardas privalo būti ilgesnis nei 3 raidės.')

              }
            }
            if (field_key === 'phone') {
              if (this.FormData.Order.phone.length > 0) {
                this.errors.phone = [];
                await axios.put(
                    process.env.VUE_APP_ROOT_API + '/api/v1/public/client/update',
                    {
                      'client_id': this.FormData.Order.client_id,
                      'field_key': field_key,
                      'field_value': this.FormData.Order[field_key]
                    }
                );
              } else {
                this.errors.phone = [];
                this.errors.phone.push('Prošome įrašyti jūsų telefono numerį.');
              }
            }
          }
        },
        async companyChanges(field_key) {
          if(field_key === 'company') {
            if (this.FormData.Order.company === true) {
              await axios.put(
                  process.env.VUE_APP_ROOT_API + '/api/v1/public/client/update',
                  {
                    'client_id': this.FormData.Order.client_id,
                    'field_key': field_key,
                    'field_value': this.FormData.Order[field_key]
                  }
              );
            }

            if(this.FormData.Order.company === false) {
              await axios.put(
                  process.env.VUE_APP_ROOT_API + '/api/v1/public/client/update',
                  {
                    'client_id': this.FormData.Order.client_id,
                    'field_key': field_key,
                    'field_value': this.FormData.Order[field_key],
                  }
              );

              this.FormData.Order.company_vat = '';
              this.FormData.Order.company_code = '';
              this.FormData.Order.company_address = '';

            }
          } else {
            if (field_key === 'company_code') {
              if (this.FormData.Order.company_code.trim().length > 0) {
                this.errors.company_code = [];
                await axios.put(
                    process.env.VUE_APP_ROOT_API + '/api/v1/public/client/update',
                    {
                      'client_id': this.FormData.Order.client_id,
                      'field_key': field_key,
                      'field_value': this.FormData.Order[field_key]
                    }
                );
              } else {
                this.errors.company_code = [];
                this.errors.company_code.push('Šis laukelis privalo būti įrašytas jei ankščiau pažymėjote, jog pildo juridinis asmuo.')
              }
            }
            if (field_key === 'company_vat') {
              if (this.FormData.Order.company_vat.length > 0) {
                this.errors.company_vat = [];
                await axios.put(
                    process.env.VUE_APP_ROOT_API + '/api/v1/public/client/update',
                    {
                      'client_id': this.FormData.Order.client_id,
                      'field_key': field_key,
                      'field_value': this.FormData.Order[field_key]
                    }
                );
              } else {
                this.errors.company_vat = [];
                this.errors.company_vat.push('Šis laukelis privalo būti įrašytas jei ankščiau pažymėjote, jog pildo juridinis asmuo.')
              }
            }
            if (field_key === 'company_address') {
              if (this.FormData.Order.company_address.length > 0) {
                this.errors.company_address = [];
                await axios.put(
                    process.env.VUE_APP_ROOT_API + '/api/v1/public/client/update',
                    {
                      'client_id': this.FormData.Order.client_id,
                      'field_key': field_key,
                      'field_value': this.FormData.Order[field_key]
                    }
                );
              } else {
                this.errors.company_address = [];
                this.errors.company_address.push('Šis laukelis privalo būti įrašytas jei ankščiau pažymėjote, jog pildo juridinis asmuo.')
              }
            }
          }

        },
        async productChanges(field_key, product, index) {

          this.tmpProduct.files = this.$refs.fileUpload.files;

          if( product ) {

            //update old by product_id
            if (product.title) {
              this.errors.product = [];
              await axios.put(
                  process.env.VUE_APP_ROOT_API + '/api/v1/public/product/updateProduct',
                  {
                    'order_id': this.FormData.Order.order_id,
                    'product_id': product.id,
                    'field_key': field_key,
                    'field_value': this.FormData.Order.products[index][field_key]
                  }
              )
            } else {
              this.errors.product = [];
              let newProduct = [product.id, 'Produkto pavadinimas yra privalomas.']
              this.errors.product.push(newProduct)
              console.log(this.errors.product)
            }
          }else {
            //create new and get product_id

            const response = await axios.post(
                process.env.VUE_APP_ROOT_API + "/api/v1/public/product/addProduct",
                {
                  'order_id': this.FormData.Order.order_id,
                  'field_key': field_key,
                  'field_value': this.tmpProduct[field_key]
                }
            );

            this.FormData.Order.products.push(response.data);
            this.tmpProduct[field_key] = '';

          }
        },
        async deleteProduct(product) {
          if(document.getElementById('submitButton').disabled === true){
            return;
          } else {
            //which product we're going to delete?
            var title = product.title;
            //be sure customer wants to delete THIS product...
            let proceed = confirm('Ar tikrai norite ištrinti produktą '+title+ '?!');
            //product delete from products array and DB
            if (proceed) {
              await axios.post(
                  process.env.VUE_APP_ROOT_API + '/api/v1/public/product/removeProduct',
                  {
                    'product_id': product.id,
                  }
              );
              console.log('product deleted')
              this.FormData.Order.products.splice(this.FormData.Order.products.findIndex(a => a.id === product.id) , 1)
            } else {
              return;
            }
          }
        },
        async addProduct() {
          // Add new empty record into Db, push product ( new obj.) into products array
          if(this.FormData.Order.order_id) {
            this.errors.other = [];
            const response = await axios.post(
                process.env.VUE_APP_ROOT_API + "/api/v1/public/product/addEmptyProduct",
                {'order_id' : this.FormData.Order.order_id}
            );

            this.FormData.Order.products.push(response.data);
          } else {
            this.errors.other = [];
            this.errors.other.push('Prašome pirma įvesti kliento informaciją.')
          }
        },
        hideShowProduct(product_id) {
          if(this.hiddenProducts.includes(product_id) === true) {
             //delete this product from array
            const index = this.hiddenProducts.indexOf(product_id);
            if (index > -1) {
              this.hiddenProducts.splice(index, 1);
            }
            return;
          }
          if (this.hiddenProducts.includes(product_id) === false) {
            //push product_id into array
            this.hiddenProducts.push(product_id)
            return;
          }


        },
        async serviceChanges(field_key, service) {

            if(field_key === 'services' && service.enabled === true) {
              const response = await axios.post(
                  process.env.VUE_APP_ROOT_API + '/api/v1/public/product/addProduct',
                  {
                    'order_id': this.FormData.Order.order_id,
                    'field_key': field_key,
                    'service_id': service.id,
                    'enabled': service.enabled,
                  }
              );
              this.FormData.Order.products.push(response.data);

            }
          if(field_key === 'services' && service.enabled === false) {

            await axios.post(
                process.env.VUE_APP_ROOT_API + '/api/v1/public/product/removeProduct',
                {
                  'order_id' : this.FormData.Order.order_id,
                  'field_key': field_key,
                  'service_id': service.id,
                  'enabled': service.enabled,
                }
            );

            for( var i = 0; i < this.FormData.Order.products.length; i++){

              if ( this.FormData.Order.products[i].service_id === service.id) {
                this.FormData.Order.products.splice(i, 1);
              }

            }
          }
        },
        serviceExplanationUp() {
          this.showServiceExplanation = true;
        },
        serviceExplanationDown() {
          this.showServiceExplanation = false;
        },
        notesExplanationUp() {
          this.showNotesExplanation = true;
        },
        notesExplanationDown() {
          this.showNotesExplanation = false;
        },
        showModal() {
          console.log('modal method')
          this.Modal = true;
        },
        showModalFromChild() {
          this.Modal = true;
        },
        validateEmail() {
          this.errors.email = [];

          // if the field is empty
          if (!this.FormData.Order.email) {
            this.errors.email.push('Šis laukelis yra privalomas.');
            return false;
          }

          // if the field is not a valid email
          const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          if (!regex.test(this.FormData.Order.email)) {
            this.errors.email.push('Prašome įvesti teisingą el.pašto adresą. Pvz.: vardas@email.com');
            return false;
          }

          // All is good
          this.errors.email = [];
          return true;
        },
        isRequired(field_key) {
          if (this.FormData.Order[field_key].length < 3) {

            this.errors[field_key].push('Laukelis "' + field_key + '" yra privalomas.');
            console.log(this.errors)
            return false;
          }
          this.errors[field_key] = [];
          console.log('name true')
          return true;
        },
        mustBeChecked() {
          if (this.FormData.Order.acceptance === false) {
            this.errors.acceptance.push('Norint tęsti užsakymą, šis laukelis privalo būti pažymėtas.');
            return false;
          }

          this.errors.acceptance = [];
          return true;
        },
        clearCookies() {
          this.$cookies.remove('iglo_token');
          location.href = process.env.VUE_APP_ROOT_API;
        },
    },
    watch() {
      for(let i = 0; i<this.FormData.Order.products.length; i++){
        if (!this.FormData.Order.products[i].hidden){
          this.FormData.Order.products[i].push(this.hidden)
        }
      }

      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }

      this.FormData.Order.status = this.$refs.progressBar.status

      // Cheking order status to show it on progress bar
      if(this.FormData.Order.status === 'filling now'){
        const items = document.getElementsByClassName('step1');

        for(const item of items){
          item.classList.add('stepProccessColor');
        }

        const mobilePB1 = document.getElementsByClassName('div1');

        for(const item of mobilePB1){
          item.classList.add('process');
        }
        const mobilePB2 = document.getElementsByClassName('div2');

        for(const item of mobilePB2){
          item.classList.add('process');
        }
      }
      if(this.FormData.Order.status === 'info_required'){
        if ( document.getElementById("step1").classList.contains('stepProcessColor') ){
          const step1 = document.getElementsByClassName('step1');

          for(const item of step1){
            item.classList.add('stepDoneColor');
            item.classList.remove('stepProcessColor')
          }
          const step1out = document.getElementsByClassName('outer-div-1');

          for(const item of step1out){
            item.classList.add('stepDoneColor');
          }
          const mobilePB1 = document.getElementsByClassName('div1');

          for(const item of mobilePB1){
            item.classList.add('success');
            item.classList.remove('process')
          }
          const mobilePB2 = document.getElementsByClassName('div2');

          for(const item of mobilePB2){
            item.classList.add('success');
            item.classList.remove('process')
          }
        }
        const step2 = document.getElementsByClassName('step2');

        for(const item of step2){
          item.classList.add('stepProcessColor');
        }

        const mobilePB4 = document.getElementsByClassName('div4');

        for(const item of mobilePB4){
          item.classList.add('processr');
        }
        const mobilePB5 = document.getElementsByClassName('div5');

        for(const item of mobilePB5){
          item.classList.add('process');
        }
      }
      if(this.FormData.Order.status === 'confirmation'){
        if ( document.getElementById("step1").classList.contains('stepProcessColor') ){
          {
            const step1 = document.getElementsByClassName('step1');

            for(const item of step1){
              item.classList.add('stepDoneColor');
              item.classList.remove('stepProcessColor')
            }
            const step1out = document.getElementsByClassName('outer-div-1');

            for(const item of step1out){
              item.classList.add('stepDoneColor');
            }
            const mobilePB1 = document.getElementsByClassName('div1');

            for(const item of mobilePB1){
              item.classList.add('success');
              item.classList.remove('process')
            }
            const mobilePB2 = document.getElementsByClassName('div2');

            for(const item of mobilePB2){
              item.classList.add('success');
              item.classList.remove('process')
            }

            document.getElementById("step3").classList.add('stepDoneColor');
            document.getElementById("step3").classList.remove('success');
          }
        }
        const step2 = document.getElementsByClassName('step2');

        for(const item of step2){
          item.classList.add('stepDoneColor');
        }
        const step2out = document.getElementsByClassName('outer-div-2');

        for(const item of step2out){
          item.classList.add('stepDoneColor');
        }

        const mobilePB4 = document.getElementsByClassName('div4');

        for(const item of mobilePB4){
          item.classList.add('success');
        }
        const mobilePB5 = document.getElementsByClassName('div5');

        for(const item of mobilePB5){
          item.classList.add('success');
        }

        const mobilePB7 = document.getElementsByClassName('div7');

        for(const item of mobilePB7){
          item.classList.add('process');
        }

        document.getElementById("step3").classList.add('stepDoneColor');
        document.getElementById("step3").classList.remove('success');

      }
      if(this.FormData.Order.status === 'confirmed'){
        if ( document.getElementById("step1").classList.contains('stepProcessColor') ){
          {
            const step1 = document.getElementsByClassName('step1');

            for(const item of step1){
              item.classList.add('stepDoneColor');
              item.classList.remove('stepProcessColor')
            }
            const step1out = document.getElementsByClassName('outer-div-1');

            for(const item of step1out){
              item.classList.add('stepDoneColor');
            }

            const mobilePB1 = document.getElementsByClassName('div1');

            for(const item of mobilePB1){
              item.classList.add('success');
              item.classList.remove('process')
            }
            const mobilePB2 = document.getElementsByClassName('div2');

            for(const item of mobilePB2){
              item.classList.add('success');
              item.classList.remove('process')
            }
          }
        }
        const step2 = document.getElementsByClassName('step2');

        for(const item of step2){
          item.classList.add('stepDoneColor');
        }
        const step2out = document.getElementsByClassName('outer-div-2');

        for(const item of step2out){
          item.classList.add('stepDoneColor');
        }

        const mobilePB4 = document.getElementsByClassName('div4');

        for(const item of mobilePB4){
          item.classList.add('success');
          item.classList.remove('process')
        }
        const mobilePB5 = document.getElementsByClassName('div5');

        for(const item of mobilePB5){
          item.classList.add('success');
          item.classList.remove('process')
        }

        const step3 = document.getElementsByClassName('step3');

        for(const item of step3){
          item.classList.add('stepDoneColor');
        }

        const mobilePB7 = document.getElementsByClassName('div7');

        for(const item of mobilePB7){
          item.classList.add('success');
          item.classList.remove('process')
        }
      }
    },
}
</script>