<template>
  <HeaderBlock v-show="windowWidth > 800"/>
  <mobileHeader v-show="windowWidth <= 800"/>
  <main id="parentDiv">
    <MainBlock />
  </main>
  <footer>
    <FooterBlock v-show="windowWidth > 425"/>
    <MobileFooter v-show="windowWidth <= 425"/>
  </footer>

</template>

<script>
import HeaderBlock from './components/header-block.vue'
import MainBlock from './components/main-block.vue'
import FooterBlock from './components/footer-block.vue'
import MobileHeader from "@/components/mobile-header";
import MobileFooter from "@/components/mobile-footer";

export default {
  name: 'App',
  components: {
    HeaderBlock,
    MainBlock,
    FooterBlock,
    MobileHeader,
    MobileFooter,
  },
  data: () => ({
    windowWidth: window.innerWidth
  }),
  watch() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
}
</script>
<style>
  * {
    font-family: 'Tahoma';
  }
</style>