<template>
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="popup border rounded-lg shadow-lg ">
        <div class="inline-block w-full " >
            <div v-show="modalInfo" class="modal-info">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias assumenda at autem cupiditate ea exercitationem in, non officia pariatur quos, similique, ut velit vitae? Dicta dignissimos laboriosam magni obcaecati soluta.</div>
            <span class="relative left-0 modal-info-icon-box cursor-pointer" @mouseover="modalInfo = !modalInfo" @mouseleave="modalInfo =! modalInfo"><font-awesome-icon icon="fa-solid fa-info" class="modal-info-icon"/></span>
            <span @click="$emit('close')" class="relative right-0 close"><font-awesome-icon icon="fa-solid fa-xmark" class="modal-x-icon" /></span>
        </div>
        <div class="textBox">
          <div class="inline-block whitespace-pre-line">
            <h2 class="modal-header">Pateiktas!</h2>
            <p class="modal-body">Jūsų užsakymas pateiktas,{{ '\n' }} peržiūrėsime ir atsakysime per 6 darbo valandas.</p>
            <p class="modal-body last">Informaciją gausite nurodytų el.paštu {{ this.email }}</p>
          </div>
          <div v-if="this.modal_errors > 0" class="inline-block whitespace-pre-line">
            <h2 class="modal-header">Nepateiktas!</h2>
            <p class="modal-body">Jūsų užsakymas nepateiktas, prašome ištaisyti klaidas.</p>
            <p class="modal-body">Klaidos: </p>
            <div class="error" v-for="(errors, index) in modal_errors" :key="index">
              <span>{{ errors }}</span>
            </div>
          </div>
        </div>

    </div>
</template>

<script>
export default {
  name: "TheModalComponent",
  props: [
    'email',
    'errors',
  ],
  setup() {

  },
  data() {
    return {
      modalInfo: false,
      req_errors: [],
      modal_errors: [],
    }
  },
  mounted() {
    console.log('modal mounted')
    let errors_array = JSON.parse(JSON.stringify(this.errors));

    // errors_array.forEach(function(error) {
    //   console.log(error)
    //   if(error.length > 0) {
    //     this.modal_error.push(error);
    //   }
    // });

    console.log(errors_array)

    for (const error of Object.entries(errors_array)) {
      console.log(error)
      if(error[1].length){
        this.modal_errors.push(error[1][0])
      }
    }

    console.log(this.modal_errors)
  },
  methods: {},
}
</script>

<style>

</style>
