<template>
  <header>
    <!--  Main header div  -->
    <div id="headerDiv" class=" customMainDiv w-100 bg-amber-200 border-b-gray-500" v-if="windowWidth > 800">
      <!--   container   -->
      <div class="container ">
        <!--    Logo div    -->
        <div class="logoDiv">
          <a :href="shopURL" class="igloLogo">
            <img src="../assets/logotipas.png" class="main-logo" width="150" height="55" alt="iglo.lt" style="">
          </a>
        </div>
        <!--   navbar    -->
        <div class="customNavbarDiv">
          <nav class="customNavbar">
            <ul class="w-full">
               <li class="customListItem order-1">
                  <a :href="shopURL" aria-current="page" class="decoration-0">
                    <span>Pradinis</span>
                  </a>
               </li>
              <li class="customListItem order-2 relative" v-on:mouseenter="isVisibleSubMenu">
                <a :href="shopURL+'/parduotuve/'">
                  <span class="pr-1">Parduotuvė</span>
                  <font-awesome-icon icon="fa-solid fa-angle-down"/>
                </a>
                <ul id="sub-menu" class="sub-menu absolute" v-on:mouseleave="notVisibleSubMenu">
                  <li class="customSubItem">
                    <a :href="shopURL+'/interjero-detales/'">
                      <span>Interjero detalės</span>
                    </a>
                  </li>
                  <li class="customSubItem">
                    <a :href="shopURL+'/baldai/'">
                      <span>Baldai</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="customListItem order-3 ">
                <a href="#">
                  <span>Užsakymai</span>
                </a>
              </li>
              <li class="customListItem order-3 ">
                <a :href="shopURL+'/portfolio/'">
                  <span>Portfolio</span>
                </a>
              </li>
              <li class="customListItem order-4 ">
                <a :href="shopURL+'/apie-mus/'">
                  <span>Apie mus</span>
                </a>
              </li>
              <li class="customListItem order-5 ">
                <a :href="shopURL+'/kontaktai/'">
                  <span>Kontaktai</span>
                </a>
              </li>
              <li class="customListItem order-5 " v-on:mouseenter="isVisibleLangMenu" >
                <a href="#">
                  <span class="pr-1">LT</span>
                  <font-awesome-icon icon="fa-solid fa-angle-down"/>
                </a>
                <ul id="lang-menu" class="sub-langMenu absolute" v-on:mouseleave="notVisibleLangMenu">
                  <div class="h-3"></div>
                  <li class="customSubItem">
                    <a :href="shopURL+'/lt/'">
                      <span>LT</span>
                    </a>
                  </li>
                  <li class="customSubItem">
                    <a :href="shopURL+'/en/'">
                      <span>EN</span>
                    </a>
                  </li>
                </ul>
              </li>
              <div id="searchIcon"  v-show="showSearch" class="customSearch"  v-on:focusout="hideSearchBlock" tabindex="0">
                <form class="flex flex-nowrap" action="https://iglo.lt/" method="get">
                    <input type="search" class="appearance-none bg-transparent border-none " name="s" placeholder="Ieškoti..." ref="search">
                    <button type="submit">
                      <font-awesome-icon icon="fa-solid fa-magnifying-glass"></font-awesome-icon>
                    </button>
                </form>
              </div>
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="cursor-pointer" v-show="!showSearch" v-on:click="showSearchBlock" />
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <mobile-header v-if="windowWidth <= 800"/>
  </header>
</template>
<script>
  import mobileHeader from "./mobile-header";

  export default {
    components: {
      mobileHeader,
    },
    setup () {},
    data: () => ({
      showSearch: false,
      windowWidth: window.innerWidth,
      shopURL: process.env.VUE_APP_SHOP_API,
    }),
    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    },

    beforeUnmount() {
      window.removeEventListener('resize', this.windowWidth);
    },
    methods: {
      isVisibleSubMenu(){
        let subMenu = document.getElementById("sub-menu");
        subMenu.classList.add("visible");
      },
      isVisibleLangMenu() {
        let langMenu = document.getElementById("lang-menu");
        langMenu.classList.add("visible");
      },
      notVisibleSubMenu(){
        let subMenu = document.getElementById("sub-menu");
        subMenu.classList.remove("visible");
      },
      notVisibleLangMenu() {
        let langMenu = document.getElementById("lang-menu");
        langMenu.classList.remove("visible");
      },
      showSearchBlock() {
        this.showSearch = true;
        let SearchIcon = document.getElementById("searchIcon");
        SearchIcon.classList.add("visible");
        this.$nextTick(function () {
          this.$refs.search.focus()
        })
      },
      hideSearchBlock() {
        let SearchIcon = document.getElementById("searchIcon");
        SearchIcon.classList.remove("visible");
        this.showSearch = false;
      },
    },
  }
</script>
<style></style>