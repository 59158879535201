<template>
  <div class="main-mobile-header-div">
    <div class="mobile-iglo-logo">
      <a :href="shopURL" >
        <img src="../assets/logotipas.png" class="main-logo" width="150" height="55" alt="iglo.lt" style="">
      </a>
    </div>
    <div class="mobile-header-burger-menu">
      <button v-on:click="showMobileNavbar">
        <font-awesome-icon icon="fa-solid fa-bars" />
      </button>
    </div>
    <div v-show="showNavbar" ref="dropdown" id="navbar" class="mobile-dropdown" v-on:focusout="hideMobileNavbar" tabindex="0">
      <font-awesome-icon icon="fa-solid fa-xmark"  class="close-dropdown" @click="hideMobileNavbar"/>
      <nav class="pt-5" >
        <ul class="w-full">
          <li class="mobile-dropdown-list-item order-1 mr-10">
            <a :href="shopURL" aria-current="page" >
              <span>Pradinis</span>
            </a>
          </li>
          <li class="mobile-dropdown-list-item order-2 mr-10">
            <a :href="shopURL+'/parduotuve/'" >
              <span class="pr-1">Parduotuvė</span>
            </a>
            <font-awesome-icon icon="fa-solid fa-angle-down" class="toggle-dropdown" @click="showInnerDropDown"/>

            <ul v-show="showDropdown" id="innerDropDown" class="mobile-inner-dropdown" >
              <li class="customSubItem">
                <a :href="shopURL+'/interjero-detales/'">
                  <span>Interjero detalės</span>
                </a>
              </li>
              <li class="customSubItem">
                <a :href="shopURL+'/baldai/'">
                  <span>Baldai</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="mobile-dropdown-list-item order-3 mr-10">
            <a :href="shopURL+'/portfolio/'">
              <span>Portfolio</span>
            </a>
          </li>
          <li class="mobile-dropdown-list-item order-4 mr-10">
            <a :href="shopURL+'/apie-mus/'">
              <span>Apie mus</span>
            </a>
          </li>
          <li class="mobile-dropdown-list-item order-5 mr-5">
            <a :href="shopURL+'/kontaktai/'">
              <span>Kontaktai</span>
            </a>
          </li>
          <li class="mobile-dropdown-list-item order-5 " >
            <a :href="shopURL+'/lt/'">
              <span class="pr-1">LT</span>
            </a>
            <span class="text-black">/ </span>
            <a :href="shopURL+'/en/'">
              <span class="pr-1">EN</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "mobile-header",
  data: () => ({
    showNavbar: false,
    showDropdown:false,
    shopURL: process.env.VUE_APP_SHOP_API,
  }),
  methods: {
    showMobileNavbar() {
      let navbar = document.getElementById("navbar");
      navbar.classList.add("visible");
      this.showNavbar = true;
      this.$nextTick(function () {
        this.$refs.dropdown.focus()
      })
    },
    hideMobileNavbar() {
      let navbar = document.getElementById("navbar");
      navbar.classList.remove("visible");
      this.showNavbar = false;
    },
    showInnerDropDown(){
      let navbar = document.getElementById("innerDropDown");
      navbar.classList.add("mobile-inner-dropdown-visible");
      this.showDropdown = !this.showDropdown
    }
  }
}
</script>

<style scoped>

</style>