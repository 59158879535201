import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/tailwind.css'
import './assets/scss/public.scss';
import VueCookies from 'vue-cookies'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleUp, faAngleDown, faMagnifyingGlass, faBars, faXmark, faPaperclip, faInfo} from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion,} from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faInstagram, } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faFacebook, faInstagram, faAngleUp, faAngleDown, faMagnifyingGlass, faBars, faXmark, faCircleQuestion, faPaperclip, faInfo)

createApp(App)
.use(VueCookies, { expire: '1d' })
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')