<template>
    <footer id="footer" role="contentinfo" class="site-footer footer-bottom-vertical fixed-footer  main-footer-inverted">
        <div class="footer-bottom">
            <div class="container ">
                <div class="footer-bottom-content">
                    <div class="footer-content-right">
                        <ul class="social-networks rounded colored-hover">
                            <li>
                                <a href="https://www.facebook.com/iglo.lt" target="_blank" class="facebook pt-3 " title="Facebook" aria-label="Facebook" rel="noopener">
                                    <font-awesome-icon icon="fa-brands fa-facebook" class="footer-icon-fb"/>
                                    <span class="name">Facebook</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/iglo.lt/" target="_blank" class="instagram pt-3 " title="Instagram" aria-label="Instagram" rel="noopener">
                                  <i class="fab fa-instagram"></i>
                                  <font-awesome-icon icon="fa-brands fa-instagram" class="footer-icon-ig"/>
                                  <span class="name">Instagram</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="footer-content-left">
                        <div class="copyrights site-info">
                            <p>iglo.lt&nbsp;© 2022&nbsp;&nbsp;·&nbsp;&nbsp;<a :href="shopURL+'/pirkimo-taisykles-ir-salygos/'" class="link-hover">Taisyklės ir sąlygos</a>&nbsp;&nbsp;·&nbsp;&nbsp;<a :href="shopURL+'/privatumo-politika/'" class="link-hover">Privatumo politika</a>&nbsp;&nbsp;·&nbsp;&nbsp;<a :href="shopURL+'/grazinimo-ir-pristatymo-politika/'" class="link-hover">Pristatymas ir grąžinimas</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  data: () => ({
    shopURL: process.env.VUE_APP_SHOP_API,
  }),
}
</script>
